<template>
  <v-container v-if="adminverified" pa-10>
    <v-row v-show="adminmsg">
      <v-col>
        <v-card dark color="primary">{{ adminmsg }}</v-card>
      </v-col>
    </v-row>
    <v-row>
      <h4>Order Processing</h4>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="ordernum"
          :items="ordernums"
          label="Order Numbers"
        ></v-select>
      </v-col>
      <v-col>
        <v-btn primary small dark @click="adminAction('inprocessOrder')"
          >Mark In Process</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="ordernum_inp"
          :items="ordernums_inp"
          label="Order Numbers"
        ></v-select>
      </v-col>
      <v-col>
        <v-btn primary small dark @click="adminAction('completeOrder')"
          >Mark Complete</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="ordernum_pend"
          :items="ordernums_pend"
          label="Pending Orders"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <h4>Soap Information</h4>
    </v-row>
    <v-row>
      <v-col>
        <v-select v-model="selitem" :items="items" label="Soaps"></v-select>
      </v-col>
      <v-col>
        <v-btn primary small dark @click="adminAction('clearimg')"
          >Clear Images</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10">
        <v-textarea
          v-model="soap_details"
          auto-grow
          label="Description"
        ></v-textarea>
      </v-col>
      <v-col cols="2">
        <v-btn primary small dark @click="adminAction('updateDesc')"
          >Update Description</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-btn primary small dark @click="paySuccess"
        >Guest Post Payment Test</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Admin",
  data() {
    return {
      adminverified: false,
      adminmsg: "Test",
      items: [],
      selitem: "",
      soap_details: "",
      ordernums: [],
      ordernum: "",
      ordernums_inp: [],
      ordernum_inp: "",
      ordernums_pend: [],
      ordernum_pend: "",
    };
  },
  watch: {
    selitem: function() {
      const path = "https://jmarie.net/api/soap?name=" + this.selitem;
      axios
        .get(path)
        .then((res) => {
          this.allsoaps = res.data.soaps[0];
          this.soap_details = this.allsoaps.details;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  methods: {
    paySuccess() {
      this.$store.commit("changeToken", "");
      this.$store.commit("changeType", this.logintype);
      this.$store.commit("changeAcctID", this.account_id);
      this.$router.push({
        name: "Orders",
        params: { paystate: "Thank you for your order!" },
      });
    },
    adminAction(doAction) {
      this.adminmsg = "";
      let currtoken = this.$store.getters.currtoken;
      if (currtoken) {
        this.logintype = this.$store.getters.currtype;
        this.account_id = this.$store.getters.curracctid;
        this.loggedin = true;
        const path = "https://jmarie.net/api/admin_access";

        let actionval = "";
        if (doAction == "clearimg") {
          actionval = this.selitem;
        }
        if (doAction == "updateDesc") {
          actionval = this.selitem;
        }
        if (doAction == "inprocessOrder") {
          actionval = this.ordernum;
        }
        if (doAction == "completeOrder") {
          actionval = this.ordernum_inp;
        }
        if (this.adminverified && !actionval) {
          this.adminmsg = "Nothing selected.";
          return;
        }
        let bulkdata = {
          id: currtoken,
          type: this.logintype,
          account_id: this.account_id,
          action: doAction,
          actionvalue: actionval,
          soap_details: this.soap_details,
        };
        axios
          .post(path, bulkdata)
          .then((res) => {
            if (res.data.status != "success") {
              this.loggedin = false;
              this.$store.commit("changeToken", "");
              this.$router.push({
                name: "Home",
              });
            } else {
              this.adminverified = true;
              this.adminmsg = res.data.adminmsg;
              this.ordernums = res.data.orders;
              this.ordernum = this.ordernums[0];
              this.ordernums_inp = res.data.orders_inp;
              this.ordernum_inp = this.ordernums_inp[0];
              this.ordernums_pend = res.data.orders_pend;
              this.ordernum_pend = this.ordernums_pend[0];

              if (doAction == "initial") {
                this.items = res.data.soaps;
                this.selitem = this.items[0];
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.$router.push({
          name: "Home",
        });
      }
    },
  },
  created() {
    this.adminAction("initial");
  },
};
</script>
